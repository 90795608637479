import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../core/services/account.service';
import { Member } from "../../../core/models/login/member";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  private member: Member;
  newPassword: string;
  confirmPassword: string;
  validationErrors: string[] = [];
  fullName: string;
  officeEmail: string;
  homeEmail: string;


  constructor(private accountService: AccountService) {
    this.member = this.accountService.getCurrentMember();
    this.fullName = this.member.fullName;
   }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PersonSecurityProfileResponse } from 'src/app/core/models/personSecurityProfileResponse';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileTFAResolver implements Resolve<PersonSecurityProfileResponse> {

  constructor(private profileService: ProfileService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersonSecurityProfileResponse> {
    return this.profileService.GetTwoFa();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IdleUserService } from '../../../core/services/idle-user.service';
import { Member } from '../../../core/models/login/member';
import { AccountService } from '../../../core/services/account.service';
import { environment } from '../../../../environments/environment';
import { SiteDetailsResponse } from '../../../core/models/login/siteDetailsResponse';
import { SiteDetails } from '../../../core/models/login/siteDetails';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  member: Member;
  returnUrl: string;
  validationErrors: string[] = [];
  credentialsInvalid: string;
  token: string;
  timeOut: number;
  enableHrAdmin: boolean;
  siteDetailResponse: SiteDetailsResponse;
  siteDetails: SiteDetails;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private accountService: AccountService,
              private idleUser: IdleUserService) {
                this.siteDetailResponse = new SiteDetailsResponse();
                this.siteDetailResponse = this.route.snapshot.data['siteDetails'];
                this.siteDetails = new SiteDetails()
                this.siteDetails = this.siteDetailResponse.SiteDetails;
              }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.token = this.route.snapshot.queryParams['jwtToken'];

    this.loginForm = this.fb.group({
      username: ['',[Validators.required]],
      password: ['',[Validators.required]],
      accessToken:['']
    });
    
    if (this.token) {
      this.loginForm.controls['username'].setValue('jwtLogin');
      this.loginForm.controls['password'].setValue('jwtLogin');
      this.loginForm.controls['accessToken'].setValue(this.token);
      this.login();
    }
  }

  login() {
    this.accountService.login(this.loginForm.value, this.siteDetails.SiteID).subscribe(x => {
      this.timeOut = environment.idleTimeout;
      this.idleUser.startWatching(this.timeOut);
      let redirect = sessionStorage.getItem('redirectUrl');
        sessionStorage.setItem('redirectUrl', '')
        if (this.returnUrl !== null && this.returnUrl !== '/') {
          this.router.navigateByUrl(redirect);
        }
        else {
          this.router.navigateByUrl('/home');
        }

      sessionStorage.setItem('previousUrl', 'Home');
      sessionStorage.setItem('username', this.loginForm.get('username').value);
    }, error => {
      let message = error.error.Message;
      if (error.error.error == "invalid_client") {
        message = "Credentials Invalid. Please verify your credentials and try again or reset your password.";
      }
      this.validationErrors = [message];
    })
  }

}

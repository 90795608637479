import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ThoughtLeadershipResponse } from '../../../core/models/thoughtLeadershipResponse';
import { ThoughtLeadershipService } from './thought-leadership.service';

@Injectable({
  providedIn: 'root'
})
export class ThoughtLeadershipResolverService implements Resolve<ThoughtLeadershipResponse> {

  constructor(private ThoughtLeadershipService: ThoughtLeadershipService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ThoughtLeadershipResponse> {
    return this.ThoughtLeadershipService.getThoughtLeadershipResponse();
  }
}

<div class="colorbar"></div>
<nav class="navigation-container">
  <!-- ********* -->
  <!-- Aside (FIXED) - Primary ::: Do not change the order of these containers! ...for semantic reasons -->
  <div class="content header-top-nav">
    <div class="header-logo">
      <a routerLink="../home" data-custom-id="home"><img src="../assets/img/logos/AlightWorklife_Logo.svg"></a>
      <div class="clientName">{{siteName}}</div>
    </div>
    <div class="header-menu" *ngIf="standardMenu == true">
      <p-megaMenu [model]="items"></p-megaMenu>
    </div>
    <div class="header-menu" *ngIf="standardMenu == false">
      <p-menubar [model]="menuItems"></p-menubar>
    </div>
  </div>
</nav>

<app-goto-admin [hrAdminRoute]="hrAdminRoute" *ngIf="enableHrAdmin"></app-goto-admin>
